import React from 'react';
import styled from "styled-components";

const Header = styled.h2`
    margin-bottom: 5%;
  `;

const Content = styled.p`
    text-align: left;
  `;

const MoreInfo = (props) => {

  const {header, content} = props;

  return (
      <React.Fragment>
        <Header>
          {header}
        </Header>
        <Content>
          {content}
        </Content>
      </React.Fragment>
  );
}

export default MoreInfo;